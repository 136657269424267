<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="createTicket()">
          <div class="card-header">
            <h4>Create Ticket</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Title</label>
                  <input type="text" class="form-control" v-model="fields.title"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    v-model="fields.description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Category of Issue</label>
                  <div class="form-group">
                    <select
                      class="form-control"
                      v-model="fields.issue_category_id"
                    >
                      <option value="" disabled>Select an option</option>
                      <option
                        v-for="issue in issues"
                        :value="issue.id"
                        :key="issue.id"
                      >
                        {{ issue.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Assign to</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.assign_to">
                      <option value="" disabled>Select an option</option>
                      <option
                        v-for="employee in employees"
                        :value="employee.id"
                        :key="employee.id"
                      >
                        {{ employee.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Device ID</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="fields.unit_serial_number"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Customer Phone number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="fields.customer_phone"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Priority</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.priority">
                      <option value="" disabled>Select an option</option>
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Community name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="fields.community"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group mb-0">
                  <label>State</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.state">
                      <option value="" disabled>Select an option</option>
                      <option
                        v-for="state in states"
                        :value="state.alias"
                        :key="state.alias"
                      >
                        {{ state.state }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group mb-0">
                  <label>Local Government Area</label>
                  <div class="form-group">
                    <select class="form-control" v-model="fields.lga">
                      <option value="" disabled>Select an option</option>
                      <option
                        v-for="lga in selectedLGA"
                        :value="lga"
                        :key="lga"
                      >
                        {{ lga }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right pt-0">
            <button class="btn btn-primary" :class="{ 'btn-progress': loading }">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TicketHelpers from "@/functions/actions/TicketRequest";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
export default {
  name: "CreateTicket",
  computed: {
    ...mapState(["loading"]),
    selectedLGA() {
      let results = this.states.find(
        (state) => state.alias === this.fields.state
      );
      return results ? this.$globalFunc.sortBy(results.lgas) : [];
    },
  },
  mounted() {
    this.retrieveIssues();
    this.retrieveEmployees();
  },
  data() {
    return {
      states: this.$globalFunc.locations(),
      issues: [],
      employees: [],
      fields: {
        issue_category_id: "",
        assign_to: "",
        state: "",
        lga: "",
        priority: "",
      },
    };
  },
  methods: {
    async retrieveIssues() {
      const response = await GeneralHelpers.getIssues();
      if (response.status) {
        this.issues = response.issues;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async retrieveEmployees() {
      const response = await GeneralHelpers.getUsersByType("employee");
      if (response.status) {
        this.employees = response.users;
      } else {
        this.showAlert(
          "Error occured",
          `${response.message}. Kindly reload the page`,
          "error"
        );
      }
    },
    async createTicket() {
      if (this.validateData()) {
        const response = await TicketHelpers.createTicket(this.fields);
        if (response.status) {
          this.showAlert("Success", "Ticket has been created successfully", "success")
          this.$router.push({
            name: "Tickets"
          })
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}. Kindly reload the page`,
            "error"
          );
        }
      }
    },
    validateData() {
      if (this.fields.title) {
        if (this.fields.description) {
          if (this.fields.issue_category_id) {
            if (this.fields.assign_to) {
              // if (this.fields.unit_serial_number) {
                if (this.fields.customer_phone) {
                  if (this.fields.priority) {
                    if (this.fields.community) {
                      if (this.fields.state) {
                        if (this.fields.lga) {
                          return true;
                        } else {
                          this.showAlert(
                            "Validation error",
                            "Select local government area",
                            "warning"
                          );
                        }
                      } else {
                        this.showAlert(
                          "Validation error",
                          "Select a state",
                          "warning"
                        );
                      }
                    } else {
                      this.showAlert(
                        "Validation error",
                        "Enter community name",
                        "warning"
                      );
                    }
                  } else {
                    this.showAlert(
                      "Validation error",
                      "Select priority level",
                      "warning"
                    );
                  }
                } else {
                  this.showAlert(
                    "Validation error",
                    "Provide customer phone number",
                    "warning"
                  );
                }
              // } else {
              //   this.showAlert(
              //     "Validation error",
              //     "Provide Device ID",
              //     "warning"
              //   );
              // }
            } else {
              this.showAlert(
                "Validation error",
                "Assign ticket to an employee",
                "warning"
              );
            }
          } else {
            this.showAlert(
              "Validation error",
              "Select category of issue",
              "warning"
            );
          }
        } else {
          this.showAlert(
            "Validation error",
            "Provide details of ticket",
            "warning"
          );
        }
      } else {
        this.showAlert(
          "Validation error",
          "Briefly describe the ticket info",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>